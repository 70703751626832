// REACT IMPORTS
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// API
import API from './../../api/';

// UTILS AND HELPERS
import setAuthToken from '../../utils/setAuthToken';

// COMPONENT IMPORTS
import QuickQuoteComponent from './../../components/quickQuote/QuickQuote';

const QuickQuote = ({ history }) => {
    const { code } = useParams();
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        API.validateCode({ userCode: code, source: 'quick-quote' })
            .then((res) => {
                if (res.data.success) {
                    setAuthToken(res.data.token);
                    setIsValidated(true);
                }
            })
            .catch((err) => {
                console.log(err.response.data);
                history.push('/');
            })
    }, []);

    return (
        <QuickQuoteComponent userCode={code} isValidated={isValidated} source="quick-quote"/>
    )
}

export default QuickQuote;
