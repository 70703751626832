export default {
    'MACQUARIE_LEASING': 7,
    'LOANU': 50,
    'LUMI': 52,
    'CLASSIC_FUNDING': 57,
    'BIZCAP': 59,
    'CAPIFY': 60,
    'FLEXI': 64,
    'GREENLIGHT_AUTO': 65,
    'JUDO': 66,
    'MORRIS_FINANCE': 69,
    'SCOTTISH_PACIFIC': 70,
    'SILVERCHEF': 71,
    'SPEEDY_FINANCE': 72,
    'SPOTCAP': 73,
    'THORN': 74,
    'WADDLE': 75,
    'BOQ': 78,
    'NAB': 79,
    'CAPITAL': 83,
    'LIBERTY': 84,
    'MOULA': 85,
    'METRO': 86,
    'PEPPER': 87,
    'WESTPAC': 88,
    'AMMF': 89,
    'NOW_FINANCE_PL': 90,
    'LATITUDE_FINANCE': 91,
    'TL_COMMERCIAL': 92,
    'PLENTI_PL': 93,
    'PLENTI': 95,
    'ANZ': 96,
    'FINANCE_1': 98,
    'AXSESS_TODAY': 99,
    'LATITUDE_FINANCE_PL': 100,
    'GET_CAPITAL': 101,
    'PROSPA': 102,
    'SOCIETY_ONE_PL': 103,
    'BIGSTONE_FINANCE': 104,
    'WISR_CAR_LOAN': 105,
    'GROW_ASSET_FINANCE': 106,
    'FLEX_FLEET': 107,
    'LOANU_PL': 109,
    'VOLKSWAGEN_FINANCIAL_SERVICES': 110,
    'MONEY_3_PL': 111,
    'MONEY_3': 112,
    'ANGLE_FINANCE': 113,
    'RACV': 114,
    'MONEY_PLACE_PL': 115,
    'BUSINESS_FUEL': 116,
    'AFS': 118,
    'CREDIT_CORP': 119,
    'SELFCO_LEASING': 121,
    'CASH_FLOW_FINANCE': 122,
    'NWC_FINANCE': 123,
    'BANJO_LOANS': 124,
    'BRANDED_FINANCIAL_SERVICES': 125,
    'AUSTRALIAN_PREMIER_FINANCE': 126,
    'WISR_PL': 127,
    'COMMONWEALTH_BANK': 128,
    'NOW_FINANCE': 129,
    'FIRST_MAC': 130,
    'MACQUARIE_DEALER': 131,
    'MULTIPLI': 132,
    'GRENKE': 133,
    'AFFORDABLE_CAR_LOANS': 136,
    'GANDG_FINANCE': 137,
    'RESIMAC': 138,
    'ALLIED_CREDIT': 139,
    'IRON_CAPITAL': 141,
    'AUTOPAY': 142,
    'ST_GEORGE': 144,
    'AZORA': 145,
    'BOQ_SPECIALIST': 146,
    'JUST_CASHFLOW_IT': 147,
    'TRUCAP': 148,
    'AUTOPAY_EXTERNAL': 149,
    'AUTOPAY_BROKER_2': 150
}
