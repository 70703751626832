// REACT IMPORTS
import React, { useState, useEffect } from 'react';

// PACKAGE IMPORTS
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

// CSS
import './customerModal.css';

// CUSTOM STYLES
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const CustomerModal = ({ 
    openModal, 
    handleCloseModal,
})  => {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [submittedForm, setSubmittedForm] = useState(false);

    // Show modal based on openModal prop value
    useEffect(() => {
        setOpen(openModal);
    }, [openModal]);

    // Save input value on inut value change
    function handleFormChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
        setErrors({ ...errors, [name]: false })
    };

    // Check form is valid
    function isFormValid() {
        const errors = {};

        if (!formData.email) {
            errors.email = "Email is required";
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (!emailRegex.test(formData.email)) {
                errors.email = "Invalid Email";
            };
        }

        if (!formData.firstName) {
            errors.firstName = "First Name is required";
        }

        if (!formData.lastName) {
            errors.lastName = "Last Name is required";
        }

        if (!formData.phone) {
            errors.phone = "Phone is required";
        } else if (formData.phone.length !== 10) {
            errors.phone = "Invalid phone number";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    // Send props to close the modal if form is valid
    function handleClick() {
        if (isFormValid()) {
            setSubmittedForm(true);
            setTimeout(() => handleCloseModal(formData),5000);
        }
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            closeAfterTransition
            onClose={() => handleCloseModal({})}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            >
            { !submittedForm ? (
                <div className={`customer-info-modal ${classes.paper}`}>
                    <p className="modal-title">LET'S GO DRIVING</p>
                    <div className={`modal-input-group input-group ${errors.firstName ? 'required' : null}`}>
                        <input
                            className="input"
                            name="firstName"
                            type="text"
                            value={formData.firstName}
                            onChange={handleFormChange}
                            placeholder="First Name"
                        />
                    </div>
                    <div className={`modal-input-group input-group ${errors.lastName ? 'required' : null}`}>
                        <input
                            className="input"
                            name="lastName"
                            type="text"
                            value={formData.lastName}
                            onChange={handleFormChange}
                            placeholder="Last Name"
                        />
                    </div>
                    <div className={`modal-input-group input-group ${errors.phone ? 'required' : null}`}>
                        <input
                            className="input"
                            name="phone"
                            type="text"
                            value={formData.phone}
                            onChange={handleFormChange}
                            placeholder="Phone"
                        />
                    </div>
                    <div className={`modal-input-group input-group ${errors.email ? 'required' : null}`}>
                        <input
                            className="input"
                            name="email"
                            type="text"
                            value={formData.email}
                            onChange={handleFormChange}
                            placeholder="Email"
                        />
                    </div>
                    <p className="form-error">{errors.email ? errors.email : null}</p>
                    <p className="form-error">{errors.firstName ? errors.firstName : null}</p>
                    <p className="form-error">{errors.lastName ? errors.lastName : null}</p>
                    <p className="form-error">{errors.phone ? errors.phone : null}</p>
                    <button className="action-buttons compare-lenders" onClick={handleClick}>COMPARE LENDERS</button>
                </div>
            ) : (
                <div className={`customer-info-modal modal-submitted ${classes.paper}`}>
                    <p className="modal-title">Good One !</p>
                    <p className="modal-submitted-text">Now that we're mates, feel free to use the "Update Results"</p>
                    <p className="modal-submitted-text">Once you're happy, simply hit the "Apply Now" button to get approved</p>
                </div>
            )}
        </Modal>
    );
}

export default CustomerModal;
