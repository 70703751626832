// REACT IMPORTS
import React, { useState, useEffect, useRef } from 'react';

// PACKAGE IMPORTS
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

// API
import API from '../../api';

// CSS
import './shopForCars.css';

// HELPERS
import Lenders from '../../utils/lenderList';
import { getSortedQuickQuoteResults } from '../../utils/quickQuoteHelper';
import applicationHelper from '../../utils/applicationHelper';

// COMPONENT IMPORTS
import CustomerModal from '../customerModal/CustomerModal';

// CUSTOM STYLES
const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: "#6aa0b8",
        color: "#ffffff",
        width: "150px",
        fontSize: "12px",
        fontWeight: "bold",
    }
}));

const ShopForCarsQuickQuote = ({
    userCode,
    isValidated,
    source,
}) => {
    const form = useRef(null);
    const tooltipClass = useStyles();
    const [errors, setErrors] = useState({});
    const [quickQuoteResult, setQuickQuoteResult] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [hasCustomerInfo, setHasCustomerInfo] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLender, setSelectedLender] = useState('');
    const [url, setUrl] = useState('');
    const [oppInfo, setOppInfo] = useState({
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        oppId: '',
    });
    const [formData, setFormData] = useState({
        assetType: 'PASSENGER_VEHICLE',
        financeType: 'CONSUMER',
        financeTerm: 5,
        amount: 50000,
        deposit: 0,
        year: 2021,
        balloon: 0,
        repaymentFrequency: 'monthly',
        livingStatus: 'OWNER',
        creditHistory: 'FIRST_TIME',
        saleType: 'DEALER',
        iframeCode: userCode
    });

    const popperOpen = Boolean(anchorEl);
    const id = popperOpen ? 'simple-popover' : undefined;

    useEffect(() => {
        if (localStorage.oppInfo) {
            let oppInfo = JSON.parse(localStorage.oppInfo);
            setOppInfo(oppInfo);

            if (oppInfo.oppId > 0) {
                setHasCustomerInfo(true);
            }
        }
    }, []);

    useEffect(() => {
        if (isValidated) {
            getQuickQuoteData();
        }
    }, [isValidated]);

    function handleFormChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
        setErrors({ ...errors, [name]: false })
    };

    function isFormValid() {
        let error = {};
        Object.keys(formData).map((key) => {
            if (key !== 'deposit' && formData[key] === '' && formData[key] !== 0) {
                error[key] = true;
            }
        })

        setErrors(error);
        return Object.keys(error).length === 0;
    }

    function getQuickQuoteData() {
        setIsLoading(true);
        API.getQuickQuote({ formData, source })
            .then((res) => {
                if (Object.keys(res.data).length > 0) {
                    setQuickQuoteResult(getSortedQuickQuoteResults(res.data, 'turnaround_time', 'ASC'));
                } else {
                    setQuickQuoteResult({});
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err.response.data);
                setIsLoading(false);
            });
    }

    function handleUpdateSearch() {
        if (hasCustomerInfo) {
            if (isFormValid()) {
                getQuickQuoteData();
            }
        } else {
            setOpenModal(true);
        }
    }

    function handleCloseModal(customerData) {
        setOpenModal(false);

        if (Object.keys(customerData).length > 0) {
            setHasCustomerInfo(true);
            API.createOpp({ ...customerData, userCode, source })
                .then((res) => {
                    setOppInfo(res.data);
                    localStorage.setItem("oppInfo", JSON.stringify(res.data));
                })
                .catch(err => {
                    console.log(err.response.data);
                });
        }
    }

    const handleClickPopper = (event, quote) => {
        if (hasCustomerInfo) {
            setSelectedLender(quote);
            setAnchorEl(event.currentTarget);
        } else {
            setOpenModal(true);
        }
    };

    const handlePopperClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (url != '') {
            form.current.submit()
        }
    }, [url])

    function handleClickSelection(applicantType) {
        let targetUrl = `${oppInfo.baseUrl}/application-form/${applicantType}/?refid=${oppInfo.oppId}`;
        setUrl(targetUrl);
    }

    function handleSort(sortBy, order) {
        setQuickQuoteResult(getSortedQuickQuoteResults(quickQuoteResult, sortBy, order));
    }

    return (
        <div className="shopforcars-quick-quote">
            <h1 className="page-heading">AUSTRALIA'S LARGEST ONLINE MARKETPLACE FOR CAR LOANS</h1>
            <h2 className="page-sub-heading">Choose from over 40 specialist car loan lenders</h2>
            <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
                <path d="M200,00 C170,100 50,100 500,400 L500,00 L0,0 Z" style={{ stroke: "none", fill: "#eaf0f4" }}></path>
            </svg>
            <div className="quick-quote-section-wrapper">
                <div className="selection-box">
                    <div className="selection-box-wrapper">
                        <div className="selection-box-section">
                            <p className="selection-header">CHOOSE YOUR FINANCE</p>
                            <label className="quick-quote-label">Loan Type</label>
                            <select
                                className={`select ${errors.financeType ? 'required' : null}`}
                                onChange={handleFormChange}
                                name="financeType"
                                value={formData.financeType}
                            >
                                <option value="CONSUMER">Personal Use</option>
                                <option value="COMMERCIAL_FULL_DOC">Business Use</option>
                            </select>
                            <label className="quick-quote-label">Loan Term</label>
                            <select
                                className={`select ${errors.financeTerm ? 'required' : null}`}
                                onChange={handleFormChange}
                                name="financeTerm"
                                value={formData.financeTerm}
                            >
                                <option value="1">1 year</option>
                                <option value="2">2 years</option>
                                <option value="3">3 years</option>
                                <option value="4">4 years</option>
                                <option value="5">5 years</option>
                                <option value="6">6 years</option>
                                <option value="7">7 years</option>
                            </select>
                            <label className="quick-quote-label">Vehicle Price</label>
                            <div className={`input-group ${errors.amount ? 'required' : null}`}>
                                <span className="input-group-addon">$</span>
                                <input
                                    className="input input-field input-value"
                                    name="amount"
                                    type="number"
                                    value={formData.amount}
                                    onChange={handleFormChange}
                                    placeholder="Asset Cost"
                                />
                            </div>
                            <label className="quick-quote-label">Deposit</label>
                            <div className={`input-group ${errors.deposit ? 'required' : null}`}>
                                <span className="input-group-addon">$</span>
                                <input
                                    className="input input-field input-value"
                                    name="deposit"
                                    type="number"
                                    value={formData.deposit}
                                    onChange={handleFormChange}
                                    placeholder=" 0 - Deposit"
                                />
                            </div>
                        </div>
                        <div className="selection-box-section">
                            <p className="selection-header">SPECIFY CONDITIONS</p>
                            <label className="quick-quote-label">Year of Manufacture</label>
                            <div className={`input-group ${errors.year ? 'required' : null}`}>
                                <input
                                    className="input input-field input-value"
                                    name="year"
                                    type="number"
                                    value={formData.year}
                                    onChange={handleFormChange}
                                    placeholder="2021 - Build Date(yr)"
                                />
                            </div>
                            <label className="quick-quote-label">Residual/Balloon</label>
                            <div className={`input-group residual-balloon ${errors.balloon ? 'required' : null}`}>
                                <input
                                    className="input input-field"
                                    name="balloon"
                                    type="number"
                                    value={formData.balloon}
                                    onChange={handleFormChange}
                                    placeholder="0 - Residual/Balloon"
                                />
                                <span className="input-group-addon">%</span>
                                <Tooltip classes={tooltipClass} title="A residual, or balloon payment is optional, it is a lump sum paid at the end of the loan term. A residual will reduce your repayments. You can re-finance or use the money from the sale of the car to pay the residual." arrow>
                                    <InfoIcon className="info-icon" />
                                </Tooltip>
                            </div>
                            <label className="quick-quote-label">Living Status</label>
                            <select
                                className={`select ${errors.livingStatus ? 'required' : null}`}
                                onChange={handleFormChange}
                                name="livingStatus"
                                value={formData.livingStatus}
                            >
                                <option value="OWNER">Property Owner</option>
                                <option value="RENTING">Renting</option>
                                <option value="BOARDING">Boarding</option>
                                <option value="WITH_RELATIVES">Living With Relatives</option>
                            </select>
                            <label className="quick-quote-label">Credit History</label>
                            <select
                                className={`select ${errors.creditHistory ? 'required' : null}`}
                                onChange={handleFormChange}
                                name="creditHistory"
                                value={formData.creditHistory}
                            >
                                <option value="FIRST_TIME">This is my first loan</option>
                                <option value="CLEAR_CREDIT">I have had a loan before</option>
                                <option value="PREV_COMP_CREDIT">I am replacing a loan</option>
                                <option value="PAID_DEFAULTS">Paid defaults</option>
                                <option value="UNPAID_DEFAULTS">Un paid defaults</option>
                                <option value="EX_BANKRUPT">Ex bankrupt</option>
                            </select>
                        </div>
                    </div>
                    <button className="update-results" onClick={handleUpdateSearch}>UPDATE SEARCH</button>
                </div>
                <div className="quick-quote-box">
                    <div className="quick-quote-header">
                        <div className="header-item">LENDER<br></br>RESULTS</div>
                        <div className="header-item">ADVERTISED<br></br>RATE
                            <Tooltip classes={tooltipClass} title="This is the interest rate published by the lender" arrow>
                                <InfoIcon className="info-icon" />
                            </Tooltip>
                            <br></br>
                            <span className="sort" onClick={() => handleSort('advertised_rate', 'ASC')}>&#9650;</span>
                            <span className="sort" onClick={() => handleSort('advertised_rate', 'DESC')}>&#9660;</span>
                        </div>
                        <div className="header-item hide-sm">COMPARISON<br></br>RATE
                            <Tooltip classes={tooltipClass} title="This includes the advertised rate, plus fees and charges" arrow>
                                <InfoIcon className="info-icon" />
                            </Tooltip>
                            <br></br>
                            <span className="sort" onClick={() => handleSort('comparison_rate', 'ASC')}>&#9650;</span>
                            <span className="sort" onClick={() => handleSort('comparison_rate', 'DESC')}>&#9660;</span>
                        </div>
                        <div className="header-item hide-sm">UPFRONT<br></br>FEE
                            <Tooltip classes={tooltipClass} title="This is the fee the lender charges to set up the contract" arrow>
                                <InfoIcon className="info-icon" />
                            </Tooltip>
                            <br></br>
                            <span className="sort" onClick={() => handleSort('upfront_fee', 'ASC')}>&#9650;</span>
                            <span className="sort" onClick={() => handleSort('upfront_fee', 'DESC')}>&#9660;</span>
                        </div>
                        <div className="header-item hide-sm">ONGOING<br></br>FEE
                            <Tooltip classes={tooltipClass} title="This is the monthly fee the lender charges to maintain the contract" arrow>
                                <InfoIcon className="info-icon" />
                            </Tooltip>
                            <br></br>
                            <span className="sort" onClick={() => handleSort('ongoing_fee', 'ASC')}>&#9650;</span>
                            <span className="sort" onClick={() => handleSort('ongoing_fee', 'DESC')}>&#9660;</span>
                        </div>
                        <div className="header-item monthly-repayment">MONTHLY<br></br>REPAYMENT
                            <Tooltip classes={tooltipClass} title="This repayment includes the upfront and ongoing fees." arrow>
                                <InfoIcon className="info-icon" />
                            </Tooltip>
                            <br></br>
                            <span className="sort" onClick={() => handleSort('monthly_repayment', 'ASC')}>&#9650;</span>
                            <span className="sort" onClick={() => handleSort('monthly_repayment', 'DESC')}>&#9660;</span>
                        </div>
                        <div className="header-item">TURNAROUND<br></br>TIME
                            <Tooltip classes={tooltipClass} title="This is the average number of days it takes the lender to settle the deal so we can get you driving" arrow>
                                <InfoIcon className="info-icon" />
                            </Tooltip>
                            <br></br>
                            <span className="sort" onClick={() => handleSort('turnaround_time', 'ASC')}>&#9650;</span>
                            <span className="sort" onClick={() => handleSort('turnaround_time', 'DESC')}>&#9660;</span>
                        </div>
                        <div className="header-item"></div>
                    </div>
                    <div className={`quick-quote-result-wrapper ${!isLoading && Object.keys(quickQuoteResult).length > 0 ? 'showScrollbar' : ''}`}>
                        {isLoading ? (
                            <div className="loader">
                                <div className="lds-ripple"><div></div><div></div></div>
                            </div>
                        ) : (
                            Object.keys(quickQuoteResult).length > 0 ? (
                                Object.keys(quickQuoteResult).map((quote, key) => (
                                    <div className="quick-quote-results" key={key}>
                                        <div className="result-item">
                                            <img className="lender-logo" src={`https://matching-engine.driveiq.com.au/wp-content/uploads/2021/08/${quote}.png`} alt={`${quickQuoteResult[quote].lender}`} />
                                        </div>
                                        <div className="result-item">{quickQuoteResult[quote].advertised_rate}</div>
                                        <div className="result-item hide-sm">{quickQuoteResult[quote].comparison_rate}</div>
                                        <div className="result-item hide-sm">{quickQuoteResult[quote].application_fee}</div>
                                        <div className="result-item hide-sm">{quickQuoteResult[quote].monthly_fee}</div>
                                        <div className="result-item monthly-repayment">{quickQuoteResult[quote].total_monthly_repayment}</div>
                                        <div className="result-item">{quickQuoteResult[quote].turnaround_time} Days</div>
                                        <div className="result-item">
                                            <button className="apply-now-button" onClick={(event) => handleClickPopper(event, quote)}>APPLY</button>
                                            <Popover
                                                id={id}
                                                open={popperOpen}
                                                anchorEl={anchorEl}
                                                onClose={handlePopperClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <p className="popper" onClick={() => handleClickSelection('sole-applicant')}>Individual</p>
                                                <p className="popper" onClick={() => handleClickSelection('company-applicant')}>Company</p>
                                                <p className="popper" onClick={() => handleClickSelection('sole-trader')}>Sole Trader</p>
                                            </Popover>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="no-results">No results</p>
                            )
                        )}
                    </div>
                </div>
            </div>
            <CustomerModal openModal={openModal} handleCloseModal={handleCloseModal} />
            {selectedLender ? (
                <form ref={form} action={url} method="post">
                    <input type="hidden" name="user-email" value={oppInfo.email} />
                    <input type="hidden" name="user-firstname" value={oppInfo.firstName} />
                    <input type="hidden" name="user-lastname" value={oppInfo.lastName} />
                    <input type="hidden" name="user-phone" value={oppInfo.phone} />
                    <input type="hidden" name="asset-type" value={applicationHelper.mapAssetType(formData.assetType)} />
                    <input type="hidden" name="finance-term" value={formData.financeTerm} />
                    <input type="hidden" name="finance-amount" value={formData.amount} />
                    <input type="hidden" name="finance-deposit" value={formData.deposit} />
                    <input type="hidden" name="finance-balloon" value={formData.balloon} />
                    <input type="hidden" name="living-status" value={formData.livingStatus} />
                    <input type="hidden" name="credit-history" value={applicationHelper.mapCreditHistory(formData.creditHistory)} />
                    <input type="hidden" name="sale-type" value={formData.saleType} />
                    <input type="hidden" name="lender" value={Lenders[selectedLender]} />
                    <input type="hidden" name="lender-establishment-fee" value={quickQuoteResult[selectedLender].lender_establishment_fee} />
                    <input type="hidden" name="other-fee" value={quickQuoteResult[selectedLender].other_fee} />
                    <input type="hidden" name="naf" value={quickQuoteResult[selectedLender].finance_total} />
                    <input type="hidden" name="customer-rate" value={quickQuoteResult[selectedLender].base_rate} />
                    <input type="hidden" name="repayment" value={quickQuoteResult[selectedLender].total_monthly_repayment.replace('$', '')} />
                    <input type="hidden" name="brokerage" value={quickQuoteResult[selectedLender].commission_dollar} />
                </form>
            ) : null}

        </div>
    )
}

export default ShopForCarsQuickQuote;
