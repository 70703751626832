import React from 'react';

function PageNotFound() {
    return (
        <div id="pageNotFound">
            <p id="lock">&#128274;</p>
            <h3>You don't have permisson to view this page</h3>
            <h3>OR</h3>
            <h3>Page does not exists</h3>
        </div>
    )
}

export default PageNotFound;