export const getSortedQuickQuoteResults = (unSortedData, sortBy, order) => {
    let sortedKeys = Object.keys(unSortedData).sort((a, b) => {
        let c = (order === 'DESC') ? b : a;
        let d = (order === 'DESC') ? a : b;

        switch(sortBy) {
            case 'advertised_rate':
                return unSortedData[c].advertised_rate.replace('%', '') - unSortedData[d].advertised_rate.replace('%', '');
            case 'comparison_rate':
                return unSortedData[c].comparison_rate.replace('%', '') - unSortedData[d].comparison_rate.replace('%', '');
            case 'upfront_fee':
                return unSortedData[c].application_fee.replace('$', '') - unSortedData[d].application_fee.replace('$', '');
            case 'ongoing_fee':
                return unSortedData[c].monthly_fee.replace('$', '') - unSortedData[d].monthly_fee.replace('$', '');
            case 'monthly_repayment':
                return unSortedData[c].total_monthly_repayment.replace('$', '') - unSortedData[d].total_monthly_repayment.replace('$', '');
            case 'turnaround_time':
                return unSortedData[c].turnaround_time - unSortedData[d].turnaround_time;
            default:
                return unSortedData[c].turnaround_time - unSortedData[d].turnaround_time;
        }
    });

    let sortedData = sortedKeys.reduce((obj, key) => {
        obj[key] = unSortedData[key];
        return obj;
    }, {})

    return sortedData;
}
