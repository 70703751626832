// REACT IMPORTS
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// PACKAGE IMPORTS
import Fade from 'react-reveal/Fade';
import jwt_decode from 'jwt-decode';
import MessengerCustomerChat from 'react-messenger-customer-chat';

// API
import API from './../../api/';

// CSS
import './shopForCars.css';

// UTILS AND HELPERS
import setAuthToken from '../../utils/setAuthToken';

// COMPONENTS IMPORTS
import ShopForCarsQuickQuote from '../../components/shopForCarsQuickQuote/ShopForCarsQuickQuote';

const ShopForCars = ({ history }) => {
    const { code } = useParams();
    const [isValidated, setIsValidated] = useState(false);
    const [url, setUrl] = useState('');
    const [partnerId, setPartnerId] = useState('');
    const [errors, setErrors] = useState({});
    const [isSending, setIsSending] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        userCode: code,
        source: 'shopforcars',
    })

    useEffect(() => {
        API.validateCode({ userCode: code, source: 'shopforcars' })
            .then((res) => {
                if (res.data.success) {
                    setAuthToken(res.data.token);
                    const decoded = jwt_decode(res.data.token);
                    setPartnerId(decoded.partnerId);
                    setUrl(decoded.baseUrl);
                    setIsValidated(true);
                }
            })
            .catch((err) => {
                console.log(err.response.data);
                history.push('/');
            })
    }, []);

    function handleFormChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
        setErrors({ ...errors, [name]: false })
    };

    function isFormValid() {
        const errors = {};

        if (!formData.email) {
            errors.email = "Email is required";
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (!emailRegex.test(formData.email)) {
                errors.email = "Invalid Email";
            };
        }

        if (!formData.name) {
            errors.name = "Name is required";
        }

        if (!formData.phone) {
            errors.phone = "Phone is required";
        } else if (formData.phone.length !== 10) {
            errors.phone = "Invalid phone number";
        }

        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    function handleFormSubmit() {
        if (isFormValid()) {
            setIsSending(true);
            API.sendEmail(formData)
            .then((res) => {
                setIsSending(false);
                setFormData({...formData, name: '', email: '', phone: '', text: ''});
            })
            .catch(err => {
                console.log(err.response.data);
                setIsSending(false);
            });
        }
    }

    return (
        <div className="page-container shopforcars-container">
            <div className="top-banner">
                <ShopForCarsQuickQuote userCode={code} isValidated={isValidated} source="shopforcars"/>
            </div>
            <div className="lenders-banner-wrapper">
                <div className="lender-banner-image"></div>
                <img className="lender-banner-boy-scooter" src="/assets/images/boy.png" alt="shopforcars"></img>
                <img className="arrow-image" src="/assets/images/arrow.png" alt="shopforcars"></img>
                <div className="lender-banner-details">
                    <h2 className="section-title">Why get pre-approved for</h2>
                    <h2 className="section-title">finance ahead of time?</h2>
                    <div className="pre-approved-wrapper">
                        <div className="left-details">
                            <ul>
                                <li> Know how much you can afford before looking for a vehicle in a dealership or online</li>
                                <li>Negotiate with car dealers more confidently with a clear budget in mind</li>
                                <li>Quicker and easier to get approved</li>
                                <li> Speed up the process of buying a vehicle you’re interested in</li>
                            </ul>
                        </div>
                        <div className="right-details">
                            <p>You will also have access to a range of finance products, including caravan insurance, boat insurance, motorcycle insurance and more.</p>
                            <p>Please note, if you are interested in a particular vehicle, you also have the option to talk to the dealer directly for finance.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pre-approved-timeline">
                <h2 className="section-title">Our smooth and easy process</h2>
                <ul className="timeline">
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-7.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <figure className="figure-img"><img src="/assets/images/Provide-Details.png" alt="shopforcars"></img></figure>
                            <Fade right>
                                <ul className="animation-element">
                                    <h3> Provide some details</h3>
                                    <li>Your car</li>
                                    <li>Yourself</li>
                                    <li>Your Finances</li>
                                </ul>
                            </Fade>
                        </div>
                    </li>
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-2.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <Fade left>
                                <ul className="animation-element slide-right ">
                                    <h3> Get personalised rate</h3>
                                    <p> Proceed with bespoke quote tailored to your circumstances</p>
                                </ul>
                            </Fade>
                            <figure className="figure-img"><img src="/assets/images/Personalised-Qutoe.png" alt="shopforcars"></img></figure>
                        </div>
                    </li>
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-3.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <figure className="figure-img"><img src="/assets/images/Get-Approved.png" alt="shopforcars"></img></figure>
                            <Fade right>
                                <ul className="animation-element slide-right ">
                                    <h3> Get Approved</h3>
                                    <li>Proof of income</li>
                                    <li>Confirm lender</li>
                                    <li>Credit check complete</li>
                                </ul>
                            </Fade>
                        </div>
                    </li>
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-5.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <Fade left>
                                <ul className="animation-element slide-right ">
                                    <h3>Confirm your loan details</h3>
                                    <li>Your car</li>
                                    <li>Who did you buy it from</li>
                                    <li>How much</li>
                                </ul>
                            </Fade>
                            <figure className="figure-img"><img src="/assets/images/Confirm-Loan-Details.png" alt="shopforcars"></img></figure>
                        </div>
                    </li>
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-6.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <figure className="figure-img"><img src="/assets/images/Prepare-Contract.png" alt="shopforcars"></img></figure>
                            <Fade right>
                                <ul className="animation-element slide-right ">
                                    <h3>Prepare Contract</h3>
                                    <li>Verify car</li>
                                    <li>Final check</li>
                                </ul>
                            </Fade>
                        </div>
                    </li>
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-2.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <Fade left>
                                <ul className="animation-element slide-right ">
                                    <h3>Sign Your Contract</h3>
                                </ul>
                            </Fade>
                            <figure className="figure-img"><img src="/assets/images/Sign-Contract.png" alt="shopforcars"></img></figure>
                        </div>
                    </li>
                    <li> 
                        <figure className="bg-img figure-left-margin"><img src="/assets/images/Arrow-6.png" alt="shopforcars"></img></figure>
                        <div className="figure-text-wrapper">
                            <figure className="figure-img"><img src="/assets/images/Pick-up-car.png" alt="shopforcars"></img></figure>
                            <Fade right>
                                <ul className="animation-element slide-right ">
                                    <h3>Seller Receives funds and you pick up your new car</h3>
                                </ul>
                            </Fade>
                        </div>
                    </li>
                </ul>
            </div>
            <svg viewBox="0 0 1440 320" className="apply-now-svg">
                <path fill="#eaf0f4" fill-opacity="1" d="M0,192L80,208C160,224,320,256,480,240C640,224,800,160,960,138.7C1120,117,1280,139,1360,149.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
            <div className="apply-section">
                <div className="container apply-section-bottom-container">
                    <h2 className="section-title">apply now for your new car!</h2>
                    <div className="apply-section-actions">
                        <a className="apply-wrapper" href={`${url}/application-form/sole-applicant/?partnerId=${partnerId}`}>
                            <figure><img src="/assets/images/Apply-Personal-Icon.png" alt="shopforcars"></img></figure>
                            <h3> Personal Use</h3>
                            <p>Choose this option if you just want to cruise around and have fun</p>
                        </a>
                        <a className="apply-wrapper" href={`${url}/application-form/company-applicant/?partnerId=${partnerId}`}>
                            <figure><img src="/assets/images/Apply-Business-Icon.png" alt="shopforcars"></img></figure>
                            <h3> Business Use</h3>
                            <p>Things are a little more serious and you know about tax and stuff</p>
                        </a>
                    </div>
                </div>
            </div>
            <svg viewBox="0 0 1440 320">
                <path fill="#eaf0f4" fill-opacity="1" d="M0,160L80,170.7C160,181,320,203,480,181.3C640,160,800,96,960,80C1120,64,1280,96,1360,112L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
            </svg>
            <div className="contact-form block-padding">
                <div className="contact-form-container">
                    <h2 className="section-title">have any questions?</h2>
                    <p className="contact-form-heading-subtext">
                        COMPLETE THE FORM BELOW OR LIVE CHAT WITH US NOW USING THE CHAT ICON ON THIS PAGE
                    </p>
                    <div className="form-group">
                        <input 
                            type="text" 
                            className="form-control"
                            name="name" 
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="form-group form-input-group">
                        <input 
                            type="email" 
                            className="form-control required" 
                            name="email" 
                            placeholder="Email" 
                            value={formData.email}
                            onChange={handleFormChange}
                        />
                        <input 
                            type="text" 
                            className="form-control required" 
                            name="phone" 
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div className="form-group">
                        <textarea 
                            className="form-control required" 
                            placeholder="Message" 
                            name="text" 
                            rows="6"
                            value={formData.text}
                            onChange={handleFormChange}
                        />
                    </div>
                    <p className="form-error">{errors.email ? errors.email : null}</p>
                    <p className="form-error">{errors.name ? errors.name : null}</p>
                    <p className="form-error">{errors.phone ? errors.phone : null}</p>
                    <div className="form-group justify-content-center">
                        <button type="submit" className="contact-us-button" onClick={handleFormSubmit}>
                            { isSending ? 'SENDING...' : 'CONTACT US TODAY'}
                        </button>
                    </div>
                </div>
            </div>
            <div className="footer">
                <p>
                    <span>Copyright &#169; 2021 Drive On Finance Pty Limited | </span>
                    <span>ABN 77 608 377 859 | </span>
                    <span>ACL 510 399</span>
                </p>
                <p>
                    <span>Contact us on <a href="tel:1300 301 264">1300 301 264</a> or </span>
                    <span>email <a href="mailto:info@driveon.finance">info@driveon.finance</a></span>
                </p>
            </div>
            <MessengerCustomerChat
                pageId="1496698703722379"
                appId="225400679545039"
            />
        </div>
    )
}

export default ShopForCars;
