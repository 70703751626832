import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import QuickQuote from './pages/quickQuote/QuickQuote';
import QuickQuoteHal from './pages/quickQuoteHal/QuickQuoteHal';
import QuickQuoteSkynet from './pages/quickQuoteSkynet/QuickQuoteSkynet';
import ShopForCars from './pages/shopforcars/ShopForCars';
import PageNotFound from './pages/PageNotFound';
import './App.css';

const App = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={PageNotFound} />
                <Route exact path="/quick-quote/:code" component={QuickQuote} />
                <Route exact path="/quick-quote-hal/:code" component={QuickQuoteHal} />
                <Route exact path="/quick-quote-skynet/:code" component={QuickQuoteSkynet} />
                <Route exact path="/shopforcars/:code" component={ShopForCars} />
                <Route component={PageNotFound} />
            </Switch>
        </Router>
    );
}

export default App;
