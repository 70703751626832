export default {
    mapAssetType: function(assetType) {
        const assetTypes = {
            "PASSENGER_VEHICLE": "1",
            "MOTORCYCLE": "1",
            "CARAVAN": "2",
            "PERSONAL_LOAN": "8",
            "BUSINESS_LOAN": "8",
        }
    
        return assetTypes[assetType];
    },
    mapCreditHistory: function(assetType) {
        const assetTypes = {
            "PAID_DEFAULTS": "1",
            "UNPAID_DEFAULTS": "2",
            "EX_BANKRUPT": "4",
            "FIRST_TIME": "5",
            "CLEAR_CREDIT": "7",
            "PREV_COMP_CREDIT": "8",
        }
    
        return assetTypes[assetType];
    },
    
}